import React from 'react';

import Logo from '@assets/bsca.svg';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import ImageText from '../../components/sections/imageText/imageText';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import Table from '../../components/sections/table/table';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      topHeader
      headerWitdh="header"
      headerStyle="flex--space-between"
      text="In-network with Blue Shield of California"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="BSCA" src={Logo} />
      }
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="BSCA" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        idCta="herobutton"
        imagedata4
        dataSection="hero_CTA"
        alt="blue shield of california"
        title="Treat joint and back pain from home"
        titleClassName=""
        text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
        textClassName=""
        // calldataGoal="Calendly Viewed"
        // callhref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        // callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs"
        // calltext="Schedule a call"
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/2gi051p"
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
        dataToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZmZpbGlhdGVfbmFtZSI6IkJsdWUgU2hpZWxkIG9mIENhbGlmb3JuaWEgKDAwMTNYMDAwMDJVR05hT1FBWCkiLCJpYXQiOiIyMDE5LTExLTA1VDE4OjE0OjA4LjY3MloiLCJqdGkiOiIzN2Q2OTRjNS0yMWVlLTRlMDMtYWM5Yy05NDQxNDdhMDBmY2QifQ.Ldd9VRdIe1MhydZOGoWv7LNuRk6sKMtcbOuGnDr3uSU"
      />
      <ImageText
        imgSize="col--lg-4"
        Filename="blueshieldca.png"
        alt="blue california"
        Position=""
        title="In-network with Blue Shield of California"
        text="Joint Academy is a covered benefit under most Blue Shield of California plans at no cost to you. Download the Joint Academy app to confirm your coverage or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/2gi051p"
        downloadclassname="btn btn--primary btn--md"
        downloadtext="get started"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/2gi051p"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <LicensedPtSliderST
        lang="us_en"
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/2gi051p"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />
      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table large',
            Filename: 'table_large_bsca@2x.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table small',
            Filename: 'table_small_bsca@2x.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED TODAY"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/2gi051p"
        subText={[
          {
            text: '*Average estimated $30 copay for PT services for 1 billable service per month. Copay and final cost may be higher and is determined by your health plan based on your plans benefits. Deductible may apply. Contact Joint Academy or your health plan for further information regarding your coverage.',
            className: 'margin-bottom--xxxs',
          },
          {
            text: '**Average $30 copay per visit for an average of 4 visits per month.',
            className: '',
          },
        ]}
      />
      <OutcomeST lang="us_en" />

      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
